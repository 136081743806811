body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-custom {
  background-color: #e0e7fc !important;
  position: sticky;
  min-height: 8vh;
  max-height: max-content;
  margin: 0;
  padding: 0;
}

.custom-link {
  color: black !important;
  font-family: 'Ubuntu', Courier, monospace !important;
  font-weight: 500 !important;
  text-transform: none !important;
  font-size: 16px !important;
}

.customBtn {

  text-transform: "uppercase";
  color: "white";
  background-color: "#348f9f";
  padding: "14px 34px";
  letter-spacing: "1px";
  margin: "0";
  font-size: "15px";
  font-weight: "700";
  transition: "all 0.5s linear";
  border: 0;
}

.customTitle {
  color: black;
  text-align: center;
}


nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown {
  display: inline-block;
  position: relative;
  padding: 10px;
  cursor: pointer;
}

.dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f9f9f9;
  padding: 0;
}

.products-dropdown:hover .dropdown {
  display: block;
}

nav ul li ul li {
  display: block;
  padding: 10px;
}

dropdown:hover {
  background-color: #e0e0e0;
}

.captcha {
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
}


.home {
  padding-top: "50px";
    width: "100%";
    display: "flex";
    min-height: "60vh";
    align-items: "center";
    justify-content: "center";
    background-color: "white";
}
/* For Chrome and Safari */
.hide-spinner::-webkit-inner-spin-button,
.hide-spinner::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* For Firefox */
.hide-spinner {
    -moz-appearance: textfield;
}

#footer{
  min-height: 7vh;
  max-height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
}